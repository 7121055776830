import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#F44B4E',
    },
    secondary: {
      main: '#f6f5ae',
    },
    white: { main: '#FFFFFF' },
    gray: { main: '#434343' },
    black: { main: '#000000' },
  },
  typography: {
    title: {
      fontFamily: 'Josefin Sans',
      fontSize: 48,
      fontWeight: 700,
      textTransform: 'none',
      color: '#FFF',
      padding: 0,
      margin: 0,
    },
    menuItems: {
      fontFamily: 'Josefin Sans',
      fontSize: 16,
      fontWeight: 700,
      textTransform: 'none',
      textDecoration: 'none',
      color: '#FFF',
    },
    button: {
      fontFamily: 'Josefin Sans',
      fontWeight: 600,
      fontSize: 16,
      textTransform: 'none',
      textDecoration: 'none',
      color: '#FFF',
    },
    input: {
      fontFamily: 'Josefin Sans',
      fontSize: 16,
      fontWeight: 700,
      textTransform: 'none',
      textDecoration: 'none',
      color: '#FFF',
    },
  },
  globals: {
    container: {
      width: '100vw',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxSizing: 'border-box',
    backgroundColor: '#000000',
    padding: '20px 90px'
    },
    leftAlign: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      padding: '100px',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});

export default theme;
