import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Navbar from '../../components/Navbar';
import Slider from '../../components/Slider';
import ImgText from '../../components/ImgText';
import theme from '../../styles';

const useStyles = makeStyles({
  container: {
    ...theme.globals.container,
    [theme.breakpoints.down('sm')]: {
      padding: '20px 20px',
    },
  },
});

const AboutUs = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Navbar />
      <ImgText />
      <Slider />
    </div>
  );
};

export default AboutUs;
