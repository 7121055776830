import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import logo from '../../resources/logos/get-techgether-logo.svg';
import theme from '../../styles';
import { Grid, Drawer } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { MenuItems } from './MenuItems'

const useStyles = makeStyles({
  navbar: {
    boxSizing: 'border-box',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '20px 0px',
    [theme.breakpoints.down('sm')]: {
      padding: '20px 0px',
    },
  },
  menuContainerLeft: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  menuContainerRight: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  logoContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  logo: {
    width: '200px',
    [theme.breakpoints.down('sm')]: {
      width: '150px',
    },
  },
  menuItem: {
    ...theme.typography.menuItems,
    margin: '0px 30px',
    color: '#FFF',
    fontFamily: 'Josefin Sans',
    fontWeight: 600,
    textAlign: 'center',
    fontSize: '20px',

    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '24px',
    },
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  hamburgerButton: {
    display: 'none',
    color: '#FFF',
    backgroundColor: 'transparent',
    border: 'none',
    [theme.breakpoints.down('sm')]: {
      padding: '20px',
      display: 'flex',
    },
  },
  hamburgerMenuContainer: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'Josefin Sans',
    fontWeight: 600,
    textAlign: 'center',
    fontSize: '16px',
    padding: '20px',
    boxSizing: 'border-box',
    background: theme.palette.primary.main,
  },
  hamburgerMenuItem: {
    ...theme.typography.menuItems,
    margin: '0px 30px',

    fontFamily: 'Josefin Sans',
    fontWeight: 600,
    textAlign: 'center',
    fontSize: '16px',
    padding: '20px',
  },
});

function Navbar() {
  const classes = useStyles();

  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <div className={classes.navbar}>
      <Drawer open={menuOpen} anchor='top'>
        <ClickAwayListener onClickAway={() => setMenuOpen(false)}>
          <div className={classes.hamburgerMenuContainer}>
            {MenuItems.map((item, index) => {
              return (
                <a href={item.link} className={classes.hamburgerMenuItem}>{item.title}</a>)
            })}
            
          </div>
        </ClickAwayListener>
      </Drawer>
      <Grid container>
        <Grid xs={5} item className={classes.menuContainerLeft}>
          <a href='/about-us' className={classes.menuItem}>
            Sobre nosotros
          </a>
          <a
            href='https://www.eventbrite.com/e/get-techgether-tickets-168485072387'
            className={classes.menuItem}
            target='_blank'
            rel='noopener noreferrer'
          >
            Boletos
          </a>
        </Grid>
        <Grid xs={2} item>
          <a href='/' className={classes.logoContainer}>
            <img src={logo} className={classes.logo} alt='logo' />
          </a>
        </Grid>
        <Grid xs={5} item className={classes.menuContainerRight}>
          <a href='/gallery' className={classes.menuItem}>
            Galería
          </a>
          <a href='/contact' className={classes.menuItem}>
            Contáctanos
          </a>
          <button
            type='button'
            onClick={() => setMenuOpen(true)}
            className={classes.hamburgerButton}
          >
            <MenuIcon />
          </button>
        </Grid>
      </Grid>
    </div>
  );
}

export default Navbar;
