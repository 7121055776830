import React from 'react';
import { makeStyles } from '@material-ui/styles';
import theme from '../../styles';
import imageEA from '../../resources/images/espacio-abierto-get-techgether4.jpeg';
import imageEA2 from '../../resources/images/espacio-abierto-get-techgether2.jpeg';
import imageEA3 from '../../resources/images/espacio-abierto-get-techgether3.jpeg';
import video1 from '../../resources/videos/video1.mp4';
import video2 from '../../resources/videos/video2.mp4';
import video3 from '../../resources/videos/video3.mp4';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles({
  container: {
    boxSizing: 'border-box',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  ImageContainer: {
    boxSizing: 'border-box',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    overflow: 'auto',
  },
  ImageRight: {
    width: '400px',
    margin: '0 30px',
    borderRadius: '20px',
    [theme.breakpoints.down('xs')]: {
      minWidth: '60vw',
    },
    [`${theme.breakpoints.down('xl')} and (orientation: landscape)`]: {
      minWidth: '30vw',
    }
  },
  Videos: {
    width: '400px',
    margin: '0 30px',
    borderRadius: '20px',
    [theme.breakpoints.down('xs')]: {
      minWidth: '60vw',
    },
    [`${theme.breakpoints.down('xl')} and (orientation: landscape)`]: {
      minWidth: '30vw',
    }
  },
  LeftContainer: {
    boxSizing: 'border-box',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  Title: {
    boxSizing: 'border-box',
    ...theme.typography.menuItems,
    textAlign: 'left',
    fontSize: '32px',
    padding: '30px',
  },
});

function Home() {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Grid container>
        <Grid item xs={12} className={classes.LeftContainer}>
          <div className={classes.Title}>{'<Fotos/>'} </div>
        </Grid>
        <Grid item xs={12} className={classes.ImageContainer}>
          <img src={imageEA} className={classes.ImageRight} alt='EA' />
          <img src={imageEA2} className={classes.ImageRight} alt='EA' />
          <img src={imageEA3} className={classes.ImageRight} alt='EA' />
        </Grid>

        <Grid item xs={12} className={classes.LeftContainer}>
          <div className={classes.Title}>{'<Videos/>'} </div>
        </Grid>
        <Grid item xs={12} type='video/mp4' className={classes.ImageContainer}>
          <video src={video1} className={classes.Videos} alt='EA' controls />
          <video
            src={video2}
            type='mov'
            className={classes.Videos}
            alt='EA'
            controls
          />
          <video src={video3} className={classes.Videos} alt='EA' controls />
        </Grid>
      </Grid>
    </div>
  );
}

export default Home;
