export const MenuItems = [

    {
        title: 'Inicio',
        link: '/'
    },
    {
        title: 'Sobre nosotros',
        link: '/about-us'
    },
    {
        title: 'Boletos',
        link: 'https://www.eventbrite.com/e/get-techgether-tickets-168485072387'
    },
    {
        title: 'Galería',
        link: '/gallery'
    },
    {
        title: 'Contáctanos',
        link: '/contact'
    },

]

