import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import theme from '../../styles';

const useStyles = makeStyles({
  container: {
    ...theme.typography.button,
    fontSize: '20px',
    backgroundColor: theme.palette.primary.main,
    boxSizing: 'border-box',
    padding: '20px 30px',
    borderRadius: '50px',
    margin: '20px 0 0 0',
    border: `4px solid ${theme.palette.primary.main}`,
    '&:hover': {
      backgroundColor: theme.palette.black.main,
      color: theme.palette.primary.main,
    },
  },
  button: {
    textTransform: 'none',
    textDecoration: 'none',
  },
});

const App = () => {
  const classes = useStyles();
  return (
    <a
      href='https://www.eventbrite.com/e/get-techgether-tickets-168485072387'
      target='_blank'
      rel='noopener noreferrer'
      className={classes.button}
    >
      <div className={classes.container}>Comprar Boletos</div>
    </a>
  );
};

export default App;
