import React from 'react';
import { makeStyles } from '@material-ui/styles';
import theme from '../../styles';
import logoEA from '../../resources/logos/ea-logo.svg';
import logoS4 from '../../resources/logos/s4-logo.svg';
import logoPLR from '../../resources/logos/plr-logo.svg';
import logoFDCLR from '../../resources/logos/fdclr-logo.svg';
import logoWitches from '../../resources/logos/witches-logo.svg';

const useStyles = makeStyles({
  '@keyframes moveleft': {
    from: {
      transform: 'translate3d(0,0,0)',
    },
    to: {
      transform: 'translate3d(-50rem,0,0)',
    },
  },
  container: {
    boxSizing: 'border-box',
    width: '100vw',
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    padding: '40px',
    overflow: 'hidden',
  },
  logo: {
    width: '160px',
    marginLeft: 40,
    animationName: '$moveleft',
    animationDuration: '25s',
    borderRadius: '4px',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'linear',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    [theme.breakpoints.down('sm')]: {
      width: '120px',
    },
  },
  menuItem: {
    ...theme.typography.menuItems,
    margin: '0px 20px',
  },
});

function Home() {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <a
        href='https://www.instagram.com/elespacioabierto/'
        target='_blank'
        rel='noopener noreferrer'
      >
        <img src={logoEA} className={classes.logo} alt='logo' />
      </a>
      <a
        href='https://www.instagram.com/strategix4/'
        target='_blank'
        rel='noopener noreferrer'
      >
        <img src={logoS4} className={classes.logo} alt='logo' />
      </a>
      <a
        href='https://www.instagram.com/parquelaruinahmo/'
        target='_blank'
        rel='noopener noreferrer'
      >
        <img src={logoPLR} className={classes.logo} alt='logo' />
      </a>
      <a
        href='https://www.instagram.com/fabricadecervezalaruina/'
        target='_blank'
        rel='noopener noreferrer'
      >
        <img src={logoFDCLR} className={classes.logo} alt='logo' />
      </a>
      <a
        href='https://www.instagram.com/fabricadecervezalaruina/'
        target='_blank'
        rel='noopener noreferrer'
      >
        <img src={logoWitches} className={classes.logo} alt='logo' />
      </a>

      {/* ------------------- */}
      <a
        href='https://www.instagram.com/elespacioabierto/'
        target='_blank'
        rel='noopener noreferrer'
      >
        <img src={logoEA} className={classes.logo} alt='logo' />
      </a>
      <a
        href='https://www.instagram.com/strategix4/'
        target='_blank'
        rel='noopener noreferrer'
      >
        <img src={logoS4} className={classes.logo} alt='logo' />
      </a>
      <a
        href='https://www.instagram.com/parquelaruinahmo/'
        target='_blank'
        rel='noopener noreferrer'
      >
        <img src={logoPLR} className={classes.logo} alt='logo' />
      </a>
      <a
        href='https://www.instagram.com/fabricadecervezalaruina/'
        target='_blank'
        rel='noopener noreferrer'
      >
        <img src={logoFDCLR} className={classes.logo} alt='logo' />
      </a>
      <a
        href='https://www.instagram.com/fabricadecervezalaruina/'
        target='_blank'
        rel='noopener noreferrer'
      >
        <img src={logoWitches} className={classes.logo} alt='logo' />
      </a>
      {/* ------------------- */}
      <a
        href='https://www.instagram.com/elespacioabierto/'
        target='_blank'
        rel='noopener noreferrer'
      >
        <img src={logoEA} className={classes.logo} alt='logo' />
      </a>
      <a
        href='https://www.instagram.com/strategix4/'
        target='_blank'
        rel='noopener noreferrer'
      >
        <img src={logoS4} className={classes.logo} alt='logo' />
      </a>
      <a
        href='https://www.instagram.com/parquelaruinahmo/'
        target='_blank'
        rel='noopener noreferrer'
      >
        <img src={logoPLR} className={classes.logo} alt='logo' />
      </a>
      <a
        href='https://www.instagram.com/fabricadecervezalaruina/'
        target='_blank'
        rel='noopener noreferrer'
      >
        <img src={logoFDCLR} className={classes.logo} alt='logo' />
      </a>
      <a
        href='https://www.instagram.com/fabricadecervezalaruina/'
        target='_blank'
        rel='noopener noreferrer'
      >
        <img src={logoWitches} className={classes.logo} alt='logo' />
      </a>
    </div>
  );
}

export default Home;
