import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Navbar from '../../components/Navbar';
import Slider from '../../components/Slider';
import emailjs from 'emailjs-com';
import Modal from 'react-modal';
import theme from '../../styles';
import TextareaAutosize from 'react-textarea-autosize';

const useStyles = makeStyles({
  container: {
    ...theme.globals.container,
    [theme.breakpoints.down('sm')]: {
      padding: '20px 20px',
    },
  },
  alertVisible: {
    display: (alertVisible) => (alertVisible ? 'flex' : 'none'),
    color: '#fff',
    fontFamily: 'Josefin Sans',
  },
  title: {
    ...theme.typography.menuItems,
    width: '100%',
    fontFamily: 'Josefin Sans',
    fontWeight: 600,
    textAlign: 'left',
    fontSize: '32px',
    [theme.breakpoints.up('lg')]: {
      padding: '10px 300px 10px 50px',
    },

  },
  input: {
    backgroundColor: '#000',
    color: theme.palette.white.main,
    borderRadius: '40px',
    padding: '20px',
    cursor: 'pointer',
    border: `4px solid ${theme.palette.primary.main}`,
    width: '100%',
    fontFamily: 'Josefin Sans',
    fontWeight: 600,
    textAlign: 'left',
    fontSize: '16px',
    margin: '10px',
    [theme.breakpoints.up('lg')]: {
      padding: '10px 300px 10px 50px',
    },
    '&:focus': {
      outline: 'none !important',
    },
    '&::placeholder': {
      color: theme.palette.white.main,
    },
  },
  inputMessage: {
    backgroundColor: '#000',
    color: theme.palette.white.main,
    borderRadius: '22px',
    cursor: 'pointer',
    border: `4px solid ${theme.palette.primary.main}`,
    width: '100%',
    fontFamily: 'Josefin Sans',
    fontWeight: 600,
    textAlign: 'left',
    fontSize: '16px',
    margin: '10px',
    padding: '20px',
    resize: 'none',
    [theme.breakpoints.up('lg')]: {
      padding: '10px 300px 10px 50px',
    },
    '&:focus': {
      outline: 'none !important',
    },
    '&::placeholder': {
      color: theme.palette.white.main,
    },
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    width: '100%',
    color: theme.palette.white.main,
    borderRadius: '40px',
    cursor: 'pointer',
    border: `2px solid ${theme.palette.primary.main}`,
    fontFamily: 'Josefin Sans',
    fontWeight: 600,
    textAlign: 'center',
    fontSize: '16px',
    margin: '10px',
    padding: '10px',
    boxSizing: 'content-box',
    [theme.breakpoints.up('lg')]: {
      padding: '10px 150px 10px 150px',
    },
    // alignSelf: 'center',
  },
  formContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const App = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [alertVisible, setAlertVisible] = useState(false);

  const classes = useStyles(alertVisible);

  const sendEmail = (e) => {
    e.preventDefault();
    if (name && email && message) {
      emailjs
        .sendForm(
          'service_jx3hjhq',
          'template_yit6kha',
          e.target,
          'user_wvjQ22nMrGRgnrlPEWvvE'
        )
        .then(
          (result) => {
            console.log(result.text);
          },
          (error) => {
            console.log(error.text);
          }
        );
      resetFields();
      setModalVisible(true);
      setAlertVisible(false);
    } else {
      setAlertVisible(true);
    }
  };

  const resetFields = () => {
    setName('');
    setEmail('');
    setMessage('');
  };

  return (
    <div className={classes.container}>
      <Modal
        isOpen={modalVisible}
        style={{
          overlay: {
            backgroundColor: 'transparent',
          },
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
          },
        }}
      >
        <div>
          <h2>Gracias!</h2>
          <br />
          <p>Tu mensaje ha sido enviado</p>
          <p>Recibirás respuesta por e-mail.</p>
          <button onClick={() => setModalVisible(false)}>Cerrar</button>
        </div>
      </Modal>
      <Navbar />
      <form onSubmit={(e) => sendEmail(e)}>
        <div className={classes.formContainer}>
          <p className={classes.title}>{'<Contáctanos/>'}</p>
          <input
            type='text'
            placeholder='Nombre'
            name='name'
            value={name}
            onChange={(e) => setName(e.target.value)}
            className={classes.input}
          ></input>
          <input
            type='email'
            placeholder='tu@correo.com'
            name='email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className={classes.input}
          ></input>
          <TextareaAutosize
            multiline
            // resize='none'
            placeholder='Mensaje'
            minRows={8}
            name='message'
            maxWidth='100px'
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            className={classes.inputMessage}
          ></TextareaAutosize>
          <input type='submit' value='Enviar' className={classes.button} />
          <p className={classes.alertVisible}>
            Por favor llena todos los campos.
          </p>
        </div>
      </form>
      <Slider />
    </div>
  );
};

export default App;
