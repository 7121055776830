// credits for the timer functionality https://www.youtube.com/watch?v=ZVOGPvo08zM
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import theme from '../../styles';

const useStyles = makeStyles({
  container: {
    boxSizing: 'border-box',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  timeContainerGridA: {
    width: '100%',
    padding: '0',
    margin: '0',
  },
  timeContainerGridB: {
    width: '100%',
    padding: '0',
    margin: '0',
  },
  timerGridContainer: {
    boxSizing: 'border-box',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      height: '50%',
    },
  },
  unitContainer: {
    padding: '10px',
  },
  timeRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  timeContainerBlockA: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },
  timeContainerBlockB: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },
  timeContainer: {
    width: '60px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  timeVariable: {
    minWidth: '60px',
    color: '#FFF',
    fontFamily: 'Josefin Sans',
    fontWeight: 700,
    textAlign: 'center',
    fontSize: '48px',
    margin: '0',
  },
  dots: {
    minWidth: '60px',
    color: '#FFF',
    fontFamily: 'Josefin Sans',
    fontWeight: 700,
    textAlign: 'center',
    fontSize: '48px',
    margin: '0',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  timeUnit: {
    minWidth: '60px',
    color: theme.palette.white.main,
    fontFamily: 'Josefin Sans',
    fontWeight: 500,
    textAlign: 'center',
    margin: '0',
  },
});

const App = () => {
  const [timerDays, setTimerDays] = useState('00');
  const [timerHours, setTimerHours] = useState('00');
  const [timerMinutes, setTimerMinutes] = useState('00');
  const [timerSeconds, setTimerSeconds] = useState('00');

  let interval;

  const startTimer = () => {
    const countdownDate = new Date('Sep 03, 2021 19:00:00').getTime();
    interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = countdownDate - now;

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      if (distance < 0) {
        //stop out timer
        clearInterval(interval);
      } else {
        //update timer
        setTimerDays(days.toString().padStart(2, '0'));
        setTimerHours(hours.toString().padStart(2, '0'));
        setTimerMinutes(minutes.toString().padStart(2, '0'));
        setTimerSeconds(seconds.toString().padStart(2, '0'));
      }
    }, 1000);
  };

  //ComponentDidMount
  useEffect(() => {
    startTimer();
    return () => {
      clearInterval(interval);
    };
  });

  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Grid container className={classes.timerGridContainer}>
        <Grid item xs={12} sm={5} className={classes.timeContainerGridA}>
          <div className={classes.timeContainerBlockA}>
            <div className={classes.timeContainer}>
              <p className={classes.timeVariable}>{timerDays}</p>
              <p className={classes.timeUnit}>días</p>
            </div>
            <div className={classes.timeContainer}>
              <p className={classes.dots}>:</p>
            </div>
            <div className={classes.timeContainer}>
              <p className={classes.timeVariable}>{timerHours}</p>
              <p className={classes.timeUnit}>horas</p>
            </div>
          </div>
        </Grid>
        <div className={classes.timeContainer}>
          <p className={classes.dots}>:</p>
        </div>
        <Grid item xs={12} sm={5} className={classes.timeContainerGridB}>
          <div className={classes.timeContainerBlockB}>
            <div className={classes.timeContainer}>
              <p className={classes.timeVariable}>{timerMinutes}</p>
              <p className={classes.timeUnit}>minutos</p>
            </div>
            <div className={classes.timeContainer}>
              <p className={classes.dots}>:</p>
            </div>
            <div className={classes.timeContainer}>
              <p className={classes.timeVariable}>{timerSeconds}</p>
              <p className={classes.timeUnit}>segundos</p>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default App;
