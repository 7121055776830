import React from 'react';
import { makeStyles } from '@material-ui/styles';
import theme from '../../styles';
import imageEA from '../../resources/images/espacio-abierto-get-techgether.jpg';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    boxSizing: 'border-box',
  },
  titleAndTextContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box',
    padding: '30px',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
    },
  },
  title: {
    ...theme.typography.menuItems,
    width: '100%',
    textAlign: 'left',
    fontSize: '32px',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      padding: '0',
    },
  },
  text: {
    ...theme.typography.menuItems,
    fontFamily: 'Josefin Sans',
    fontWeight: 600,
    textAlign: 'left',
    fontSize: '24px',
    maxWidth: '600px',
    [theme.breakpoints.down('sm')]: {
      padding: '0',
    },
  },
  imageContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    padding: '30px',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      padding: '30px',
    },
  },
  image: {
    width: '100%',
    height: 'auto',
    borderRadius: '20px',
  },
});

function Home() {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Grid container>
        <Grid item xs={12} md={6} className={classes.titleAndTextContainer}>
          <p className={classes.title}>{'<Objetivo/>'}</p>

          <p className={classes.text}>
            Enfocados en la innovación, buscamos reactivar nuestra comunidad
            Tech, e interesarlos en el movimiento STEAM que impulsa la educación
            y el talento, los empleos del futuro y la innovación con visión
            social e incluyente.
          </p>
        </Grid>
        <Grid item xs={12} md={6} className={classes.imageContainer}>
          <img src={imageEA} className={classes.image} alt='EA' />
        </Grid>
      </Grid>
    </div>
  );
}

export default Home;
